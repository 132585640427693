import React, { useState } from "react"
import { Checkbox, Typography, Link, FormControl, FormControlLabel } from "@mui/material"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { toast } from "react-toastify"

/**
 * UserObject kuvaa rekisteröintilomakkeen kenttiä.
 */
interface UserObject {
	fullName: string
	email: string
	password: string
	passwordAgain: string
}

/**
 * Tarkistetaan, että email-muoto on oikea.
 */
const validateEmail = (email: string): boolean => /\S+@\S+\.\S+/.test(email)

/**
 * checkRegisterEventValues varmistaa, että kaikki syöttökentät ovat validit
 * ja että käyttöehdot on hyväksytty.
 * Palauttaa true, jos kaikki ok; false, jos virheitä löytyy.
 */
const checkRegisterEventValues = (userObj: UserObject, agreedTerms: boolean): boolean => {
	const errors: string[] = []

	if (!userObj.fullName) errors.push("Käyttäjän nimi vaaditaan rekisteröityessä")
	if (!userObj.email) errors.push("Sähköpostiosoite vaaditaan rekisteröityessä")
	else if (!validateEmail(userObj.email)) errors.push("Väärin muotoiltu sähköposti")

	if (!userObj.password) errors.push("Salasana vaaditaan")
	else if (userObj.password.length < 8) errors.push("Salasanan minimi pituus on 8 merkkiä")

	if (userObj.password !== userObj.passwordAgain) errors.push("Salasanat eivät täsmää")

	if (!agreedTerms) errors.push("Rekisteröityminen vaatii käyttöehtojen hyväksymisen")

	if (errors.length > 0) {
		toast.error(
			<ul>
				{errors.map((error, index) => (
					<li key={index}>{error}</li>
				))}
			</ul>,
			{ role: "global-top-center" }
		)
		return false
	}

	return true
}

/**
 * Props-tyyppi, jossa registerUser on funktio, joka vastaanottaa
 * { fullName, email, password } ja palauttaa Promise<any>.
 */
interface RegisterFormProps {
	registerUser: (userObj: { fullName: string; email: string; password: string }) => Promise<any>
}

const RegisterForm = ({ registerUser }: RegisterFormProps) => {
	const [agreedTermsOk, setAgreedTermsOk] = useState(false)
	const [userObj, setUserObj] = useState<UserObject>({
		fullName: "",
		email: "",
		password: "",
		passwordAgain: ""
	})

	/**
	 * Käsittelee käyttöehtojen Checkboxin tilaa
	 */
	const handleCheckBox = () => {
		setAgreedTermsOk(!agreedTermsOk)
	}

	/**
	 * Lähettää rekisteröintilomakkeen tiedot backendille, jos ne ovat validit.
	 */
	const handleRegisterSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()

		// Ensin validoidaan lomaketiedot
		if (!checkRegisterEventValues(userObj, agreedTermsOk)) return

		// Poimitaan vain tarvittavat kentät (passwordAgain jää pois)
		const requestBody = {
			fullName: userObj.fullName.trim(),
			email: userObj.email.trim().toLowerCase(),
			password: userObj.password
		}

		// Suoritetaan registerUser-kutsu
		let registerRes
		try {
			registerRes = await registerUser(requestBody)
		} catch (error) {
			toast.error("Rekisteröinti epäonnistui. Yritä myöhemmin uudelleen.")
			return
		}

		// Käsitellään tulos
		if (registerRes.status === 400) {
			if (registerRes.message === "Network Error") {
				toast.error("Yhteyden muodostaminen palvelimelle epäonnistui. Yritä myöhemmin uudelleen.")
			} else if (registerRes.error && registerRes.error.response && registerRes.error.response.status === 403) {
				toast.error("Tievahdin käyttö on toistaiseksi rajoitettu. Antamasi sähköposti ei ole sallittujen listalla.")
			} else if (registerRes.error && registerRes.error.response && registerRes.error.response.status === 409) {
				toast.error("Sähköpostiosoiteella on jo rekisteröity käyttäjä.")
			} else {
				toast.error("Käyttäjän luonti epäonnistui.")
			}
		} else if (registerRes.status < 300) {
			toast.success("Käyttäjä luotu! Tarkista sähköpostistasi vahvistuslinkki.", { role: "global" })
			window.history.pushState({}, document.title, "/")
		} else {
			toast.error("Rekisteröinti epäonnistui.")
		}
	}

	return (
		<div className="register-form">
			<div className="registerFormTitle">Rekisteröidy veloituksetta</div>

			<form autoComplete="off" onSubmit={handleRegisterSubmit}>
				<input
					className="registerFormInput"
					type="text"
					name="fullName"
					placeholder="Etu- ja Sukunimi"
					value={userObj.fullName}
					onChange={(e) => setUserObj({ ...userObj, fullName: e.target.value })}
				/>
				<input
					className="registerFormInput"
					type="text"
					name="regEmail"
					placeholder="Sähköpostiosoite"
					value={userObj.email}
					onChange={(e) => setUserObj({ ...userObj, email: e.target.value })}
				/>
				<input
					className="registerFormInput"
					type="password"
					name="regPassword"
					autoComplete="new-password"
					placeholder="Salasana"
					value={userObj.password}
					onChange={(e) => setUserObj({ ...userObj, password: e.target.value })}
				/>
				<input
					className="registerFormInput"
					type="password"
					name="regPasswordAgain"
					autoComplete="new-password"
					placeholder="Salasana uudelleen"
					value={userObj.passwordAgain}
					onChange={(e) => setUserObj({ ...userObj, passwordAgain: e.target.value })}
				/>

				<button type="submit" className="registerFormButton">
					Rekisteröidy
				</button>
			</form>

			<FormControl className="terms-checkbox" style={{ marginTop: 16 }}>
				<FormControlLabel
					control={<Checkbox checked={agreedTermsOk} onChange={handleCheckBox} color="primary" />}
					label={
						<Typography variant="body2" style={{ fontSize: "0.7em", color: "#ffffff" }}>
							Hyväksyn{" "}
							<Link href="https://tietosuoja.tievahti.fi/" target="_blank">
								käyttöehdot ja tietosuojaselosteen <OpenInNewIcon fontSize="small" style={{ width: "0.8em", height: "0.8em" }} />
							</Link>{" "}
							mukaisen tietojen käytön.
						</Typography>
					}
				/>
			</FormControl>
		</div>
	)
}

export default RegisterForm
